import React from 'react'
import { FadeIn, Heading, Section } from '@klickmarketing/react-components'
import { Container } from '@material-ui/core'

import BreakingNews from './BreakingNews'

const FeaturedStories = () => {
  const StoryItem = <BreakingNews />
  return (
    <Section id="featured-stories" bgcolor="background.off" overflow="hidden">
      <Container maxWidth="sm">
        <FadeIn from="bottom">
          <Heading variant="h2" align="center">
            Featured Stories
          </Heading>
        </FadeIn>
      </Container>
      <FadeIn from="bottom">
        <Container maxWidth="lg">{StoryItem}</Container>
      </FadeIn>
    </Section>
  )
}

export default FeaturedStories
