import React from 'react'
import { Heading, P, Link } from '@klickmarketing/react-components'
import { Box } from '@material-ui/core'
import { graphql, useStaticQuery } from 'gatsby'

import { ContentImageGrid } from '../base'
import { JohnBrownstein } from '../BioCard/BioCards'

const CovidNearYouGrid = (props) => {
  const data = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "collaborations/covid-near-you.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 896, layout: CONSTRAINED)
        }
      }
    }
  `)
  return (
    <ContentImageGrid
      imageProps={{
        sources: data.file.childImageSharp.gatsbyImageData,
        alt: 'Photo of Laptop showing CovidNearYou.org interface',
      }}
      {...props}
    >
      <Heading variant="h4" font="primary">
        COVID Near You
      </Heading>
      <P variant="subtitle1">
        <strong>
          Bolstering efforts to take symptom tracking to the next level.
        </strong>
      </P>
      <P>
        COVID Near You is a community symptom tracker that collects
        self-reported health data on a daily basis to form a greater picture of
        public health. This tool has already successfully launched in the United
        States, and Klick Health has worked with the teams at Harvard Medical
        School and Boston Children’s Hospital to make it available across North
        America, as well as implementing an SMS text alert system for all
        countries.
      </P>
      <P>
        <Link type="text" href="https://covidnearyou.org">
          Learn more about COVID Near You.
        </Link>
      </P>
      <Box pt={3}>
        <JohnBrownstein />
      </Box>
    </ContentImageGrid>
  )
}

export default CovidNearYouGrid
