import React from 'react'
import {
  Link,
  Heading,
  KlickLogo,
  NoWrap,
  P,
  ThemeProvider,
  tracking,
} from '@klickmarketing/react-components'
import { Box, Container } from '@material-ui/core'
import GetAppIcon from '@material-ui/icons/GetApp'
import styled from 'styled-components'

import { ButtonIconReveal } from '../base'

import Hero from './Hero'

const trackDownload = (label) => {
  tracking.track({
    event: 'file_download',
    eventModel: {
      file_extension: 'zip',
      file_name: 'Klick_Health_COVID19_3D_Assets_2020-04-16.zip',
      track_id: 'hero_3d_renders',
      link_url:
        'https://cdn.klick.com/klick-covid19/Klick_Health_COVID19_3D_Assets_2020-04-16.zip',
    },
  })
}

const HeroHome = () => {
  return (
    <ThemeProvider themeType="onBlack">
      <Hero publicId="v1586466271/covid-19/videos/covid19-banner" pt={0} pb={0}>
        <Box py={5}>
          <Box pb={5} textAlign="center">
            <Logo themeType="onPrimary" />
          </Box>
          <Heading variant="h1" align="center">
            <NoWrap>COVID-19</NoWrap> Portal
          </Heading>
          <Container maxWidth="sm">
            <P variant="h6" align="center" paragraph={false}>
              The COVID-19 outbreak is changing every day. So is what we’re
              doing to&nbsp;respond.
            </P>
          </Container>
          <Box textAlign="right">
            <ButtonIconReveal
              role="link"
              component={Link}
              linkTracking={false}
              variant="outlined"
              color="inherit"
              font="primary"
              href="https://cdn.klick.com/klick-covid19/Klick_Health_COVID19_3D_Assets_2020-04-16.zip"
              target="_blank"
              onClick={() => trackDownload('COVID-19 Virus Model')}
            >
              <GetAppIcon />
              &nbsp;Get COVID-19 3D Renders (10.5MB)
            </ButtonIconReveal>
          </Box>
        </Box>
      </Hero>
    </ThemeProvider>
  )
}

const Logo = styled(KlickLogo)`
  height: 90px;
  color: #fff;
  display: inline-block;
`

export default HeroHome
