import React from 'react'
import {
  Link,
  FadeIn,
  Heading,
  match,
  NoWrap,
  P,
  Section,
} from '@klickmarketing/react-components'
import { Container, Grid, useMediaQuery } from '@material-ui/core'
import styled from 'styled-components'

import { SectionItem, ShowMore } from '../base'

const InTheNews = () => {
  const isMobile = useMediaQuery(match.isXS)
  const spacing = isMobile ? 4 : 10
  return (
    <Section id="news" overflow="hidden">
      <Container maxWidth="lg">
        <FadeIn from="bottom">
          <div>
            <Heading variant="h2" align="center">
              In The News
            </Heading>
            <P align="center" paragraph={false}>
              A collection of just some of the stories that have helped us
              spread the word.
            </P>
          </div>
        </FadeIn>
        <SectionItem>
          <Grid container spacing={spacing}>
            <ShowMore blocks={4}>
              <FadeIn from="left">
                <Grid item xs={12} sm={6}>
                  <P>April 13, 2020</P>
                  <StyledNewsP variant="h5" font="primary">
                    “As the COVID-19 epidemic continues to ravage the planet,
                    Klick Health has tapped into its deep well of resources and
                    extensive network to provide protective equipment for
                    medical professionals on the treatment frontlines.”
                  </StyledNewsP>
                  <StyledNewsP variant="h5" font="primary">
                    &mdash;&nbsp;
                    <Link
                      type="text"
                      href="https://www.mmm-online.com/home/channel/agencies/klick-donates-masks-intubation-boxes-to-medical-pros-on-the-covid-frontlines/"
                    >
                      MM&amp;M
                    </Link>
                  </StyledNewsP>
                </Grid>
              </FadeIn>
              <FadeIn from="right">
                <Grid item xs={12} sm={6}>
                  <P>April 07, 2020</P>
                  <StyledNewsP variant="h5" font="primary">
                    “Given PPE shortages at many hospitals, clinicians
                    performing aerosol-generating procedures such as intubation
                    are improvising extra protection...”
                  </StyledNewsP>
                  <StyledNewsP variant="h5" font="primary">
                    &mdash;&nbsp;
                    <Link
                      type="text"
                      href="https://www.medscape.com/viewarticle/928328"
                    >
                      Medscape
                    </Link>
                  </StyledNewsP>
                </Grid>
              </FadeIn>
              <FadeIn from="left">
                <Grid item xs={12} sm={6}>
                  <P>April 07, 2020</P>
                  <StyledNewsP variant="h5" font="primary">
                    “Marketers and their partners are also working on other
                    innovative solutions to help limit the spread of this
                    pandemic.”
                  </StyledNewsP>
                  <StyledNewsP variant="h5" font="primary">
                    &mdash;&nbsp;
                    <Link
                      type="text"
                      href="https://www.pm360online.com/the-impact-of-covid-19-on-life-sciences-marketing/"
                    >
                      PM360
                    </Link>
                  </StyledNewsP>
                </Grid>
              </FadeIn>
              <FadeIn from="right">
                <Grid item xs={12} sm={6}>
                  <P>April 06, 2020</P>
                  <StyledNewsP variant="h5" font="primary">
                    “Humber River Hospital and Klick Health are working together
                    to help local hospitals provide their frontline workers with
                    an extra line of defence during <NoWrap>COVID-19</NoWrap>.”
                  </StyledNewsP>
                  <StyledNewsP variant="h5" font="primary">
                    &mdash;&nbsp;
                    <Link
                      type="text"
                      href="https://globalnews.ca/video/6786149/coronavirus-intubation-box-donations-to-help-protect-frontline-hospital-workers"
                    >
                      Global News
                    </Link>
                  </StyledNewsP>
                </Grid>
              </FadeIn>
              <FadeIn from="left">
                <Grid item xs={12} sm={6}>
                  <P>April 02, 2020</P>
                  <StyledNewsP variant="h5" font="primary">
                    “Whitehead, a data scientist, is part of this group of over
                    70 people, and he makes the case for why it's important to
                    be fast and granular with the data.”
                  </StyledNewsP>
                  <StyledNewsP variant="h5" font="primary">
                    &mdash;&nbsp;
                    <Link
                      type="text"
                      href="https://www.qpbriefing.com/2020/04/02/how-to-read-and-analyze-daily-coronavirus-data-in-ontario/"
                    >
                      QP Briefing
                    </Link>
                  </StyledNewsP>
                </Grid>
              </FadeIn>
              <FadeIn from="right">
                <Grid item xs={12} sm={6}>
                  <P>March 20, 2020</P>
                  <StyledNewsP variant="h5" font="primary">
                    “Klick Health has made its best practices to keep staff safe
                    available on its website, as well as offering its digital
                    health lab’s rapid prototyping capability to bring ideas for
                    selected digital health solutions to life...”
                  </StyledNewsP>
                  <StyledNewsP variant="h5" font="primary">
                    &mdash;&nbsp;
                    <Link
                      type="text"
                      href="https://strategyonline.ca/2020/03/20/how-agencies-are-keeping-morale-up/"
                    >
                      Strategy
                    </Link>
                  </StyledNewsP>
                </Grid>
              </FadeIn>
              <FadeIn from="left">
                <Grid item xs={12} sm={6}>
                  <P>March 18, 2020</P>
                  <StyledNewsP variant="h5" font="primary">
                    “The company’s prep plans at covid19.klick.com are available
                    to other companies to help them proactively prepare.”
                  </StyledNewsP>
                  <StyledNewsP variant="h5" font="primary">
                    &mdash;&nbsp;
                    <Link
                      type="text"
                      href="https://cdn.klick.com/klick-covid19/Toronto_Sun_Working_From_Home.pdf"
                    >
                      Toronto Sun
                    </Link>
                  </StyledNewsP>
                </Grid>
              </FadeIn>
              <FadeIn from="right">
                <Grid item xs={12} sm={6}>
                  <P>March 17, 2020</P>
                  <StyledNewsP variant="h5" font="primary">
                    “Klick Health encouraged its approximately 1,000 employees
                    to work from home last week and then moved to closing its
                    office on Friday.”
                  </StyledNewsP>
                  <StyledNewsP variant="h5" font="primary">
                    &mdash;&nbsp;
                    <Link
                      type="text"
                      href="https://www.benefitscanada.com/news/employers-moving-to-remote-work-to-help-flatten-the-coronavirus-curve-143753"
                    >
                      Benefits Canada
                    </Link>
                  </StyledNewsP>
                </Grid>
              </FadeIn>
              <FadeIn from="left">
                <Grid item xs={12} sm={6}>
                  <P>March 16, 2020</P>
                  <StyledNewsP variant="h5" font="primary">
                    “Life sciences agency Klick Health has deployed its digital
                    innovation group to work with outside organizations...”
                  </StyledNewsP>
                  <StyledNewsP variant="h5" font="primary">
                    &mdash;&nbsp;
                    <Link
                      type="text"
                      href="https://www.prweek.com/article/1677290/coronavirus-briefing-launch-edition-pandemic-implications?utm_source=feedburner&utm_medium=feed&utm_campaign=Feed%3A+PrweekUsNews+%28PRWeek+US+News%29"
                    >
                      PR Week
                    </Link>
                  </StyledNewsP>
                </Grid>
              </FadeIn>
              <FadeIn from="right">
                <Grid item xs={12} sm={6}>
                  <P>March 13, 2020</P>
                  <StyledNewsP variant="h5" font="primary">
                    “Klick Labs, the digital innovation group at Klick Health,
                    is tapping into the agency’s expertise to help combat
                    COVID-19.”
                  </StyledNewsP>
                  <StyledNewsP variant="h5" font="primary">
                    &mdash;&nbsp;
                    <Link
                      type="text"
                      href="https://www.mmm-online.com/home/channel/agencies/klick-health-lends-expertise-to-covid-19-digital-health-tools/"
                    >
                      MM&M
                    </Link>
                  </StyledNewsP>
                </Grid>
              </FadeIn>
              <FadeIn from="left">
                <Grid item xs={12} sm={6}>
                  <P>March 11, 2020</P>
                  <StyledNewsP variant="h5" font="primary">
                    “Around 600 Toronto employees of health-marketing agency
                    Klick Health have been working at home since Monday...”
                  </StyledNewsP>
                  <StyledNewsP variant="h5" font="primary">
                    &mdash;&nbsp;
                    <Link
                      type="text"
                      href="https://www.theglobeandmail.com/business/article-more-companies-asking-employees-to-work-from-home-to-slow-spread-of/"
                    >
                      The Globe and Mail
                    </Link>
                  </StyledNewsP>
                </Grid>
              </FadeIn>
              <FadeIn from="right">
                <Grid item xs={12} sm={6}>
                  <P>March 11, 2020</P>
                  <StyledNewsP variant="h5" font="primary">
                    “There’s no sign of COVID-19 at [Klickster Peter
                    Flaschner]’s office, but they’re set up to work remotely
                    anyway.”
                  </StyledNewsP>
                  <StyledNewsP variant="h5" font="primary">
                    &mdash;&nbsp;
                    <Link
                      type="text"
                      href="https://www.youtube.com/watch?v=CmvP0MTWFvk"
                    >
                      CBC News: The National
                    </Link>
                  </StyledNewsP>
                </Grid>
              </FadeIn>
              <FadeIn from="left">
                <Grid item xs={12} sm={6}>
                  <P>March 11, 2020</P>
                  <StyledNewsP variant="h5" font="primary">
                    “With business leaders moving quickly to test and improve
                    continuity plans as significant disruption related to
                    COVID-19 appears likely, industry employers including Google
                    Canada, Shopify, Klick Health and Abacus have already told
                    staff to work from home.”
                  </StyledNewsP>
                  <StyledNewsP variant="h5" font="primary">
                    &mdash;&nbsp;
                    <Link
                      type="text"
                      href="https://the-message.ca/2020/03/11/as-covid-19-fears-escalate-some-businesses-institute-remote-work-policies/"
                    >
                      The Message
                    </Link>
                  </StyledNewsP>
                </Grid>
              </FadeIn>
            </ShowMore>
          </Grid>
        </SectionItem>
      </Container>
    </Section>
  )
}

const StyledNewsP = styled(P)`
  line-height: 1.3;
`

export default InTheNews
