import React from 'react'
import {
  FadeIn,
  Heading,
  Hr,
  P,
  Section,
} from '@klickmarketing/react-components'
import { Container } from '@material-ui/core'

import Advisors from './Advisors'
import CovidNearYouGrid from './CovidNearYouGrid'
import DockHealthGrid from './DockHealthGrid'
import HealthmapGrid from './HealthmapGrid'
import HowsMyFlatteningGrid from './HowsMyFlatteningGrid'
import IntubationBoxGrid from './IntubationBoxGrid'

const Collaborations = () => {
  return (
    <Section id="collaborations" overflow="hidden">
      <FadeIn from="bottom">
        <Container maxWidth="sm">
          <Heading variant="h2" align="center">
            Collaborations &amp;&nbsp;Creations
          </Heading>
          <P align="center" paragraph={false}>
            How we’re working with community partners to give back to the people
            who are giving their all on the frontlines of healthcare.
          </P>
        </Container>
      </FadeIn>
      <Advisors />
      <Container maxWidth="lg">
        <Hr />
      </Container>
      <IntubationBoxGrid id="intubation-box" direction="row" />
      <HealthmapGrid id="healthmap" alt direction="row-reverse" />
      <HowsMyFlatteningGrid id="howsmyflattening" direction="row" />
      <CovidNearYouGrid
        id="covid-near-you-activity"
        alt
        direction="row-reverse"
      />
      <DockHealthGrid id="dock-health" direction="row" />
    </Section>
  )
}

export default Collaborations
