import React from 'react'
import { FadeIn, Heading, P, Section } from '@klickmarketing/react-components'
import { Container } from '@material-ui/core'

import ConductorGrid from './ConductorGrid'
import SafetyProtocolsGrid from './SafetyProtocolsGrid'

const Playbooks = () => {
  return (
    <Section id="playbooks" overflow="hidden">
      <FadeIn from="bottom">
        <Container maxWidth="lg">
          <Heading variant="h2" align="center">
            Playbooks &amp; Tools
          </Heading>
          <P align="center" paragraph={false}>
            Digital tools and resources to help organizations manage COVID-19.
          </P>
        </Container>
      </FadeIn>
      <SafetyProtocolsGrid id="safety-protocols" />
      <ConductorGrid id="conductor" direction="row-reverse" />
    </Section>
  )
}

export default Playbooks
