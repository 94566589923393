import React from 'react'
import { Section } from '@klickmarketing/react-components'
import { Box, Container } from '@material-ui/core'

import VideoBackground from '../VideoBackground/VideoBackground'

const Hero = ({ publicId, children, color = 'white', ...rest }) => {
  return (
    <Section {...rest}>
      <Box position="relative" zIndex="500" color={color}>
        <Container maxWidth="lg">{children}</Container>
      </Box>
      {publicId && <VideoBackground publicId={publicId} />}
    </Section>
  )
}

export default Hero
