import React from 'react'
import { Heading, NoWrap, P, Link } from '@klickmarketing/react-components'
import { Box } from '@material-ui/core'
import { graphql, useStaticQuery } from 'gatsby'

import { ContentImageGrid } from '../base'
import { MichaelDocktor } from '../BioCard/BioCards'

const DockHealthGrid = (props) => {
  const data = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "collaborations/dock-health.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 896, layout: CONSTRAINED)
        }
      }
    }
  `)
  return (
    <ContentImageGrid
      imageProps={{
        sources: data.file.childImageSharp.gatsbyImageData,
        alt: 'Photo of a phone displaying the CDC Coronavirus/COVID-19 Toolkit',
      }}
      {...props}
    >
      <Heading variant="h4" font="primary">
        Dock Health
      </Heading>
      <P variant="subtitle1">
        <strong>Helping healthcare workers focus on what matters.</strong>
      </P>
      <P>
        Dock Health is a HIPAA compliant task management and collaboration
        platform designed for healthcare and has created a “CDC Coronavirus/
        <NoWrap>COVID-19</NoWrap> Toolkit”. This free tool can help alleviate
        administrative burden in your medical practice and leverages CDC{' '}
        <NoWrap>COVID-19</NoWrap> resources and guidelines to help you evolve
        with the crisis. Klick Health is supporting the Dock Health team with
        strategic and creative thinking to help amplify this vital tool and get
        it into the hands of HCPs that need it.
      </P>
      <P>
        <Link type="text" href="https://www.dockhealth.com">
          Learn more about Dock Health.
        </Link>
      </P>
      <Box pt={3}>
        <MichaelDocktor />
      </Box>
    </ContentImageGrid>
  )
}

export default DockHealthGrid
