import React from 'react'
import { HeroVideo } from '@klickmarketing/react-components'
import styled from 'styled-components'

import { match } from '../../common/breakpoint'

const VideoBackground = (props) => {
  return (
    <VideoContainer>
      <LoopedVideo
        loopedId={props.publicId}
        isFullViewport={true}
        muted
        quality={80}
        videoProps={{
          'aria-hidden': true,
        }}
      />
    </VideoContainer>
  )
}

const VideoContainer = styled.div`
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: 0;
`

const LoopedVideo = styled(HeroVideo)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 100%;
  min-height: 100%;

  @media ${match.isPortrait} {
    height: 100%;
  }
`

export default VideoBackground
