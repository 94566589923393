import React from 'react'
import { Link, Heading, NoWrap, P } from '@klickmarketing/react-components'
import { Box } from '@material-ui/core'
import { graphql, useStaticQuery } from 'gatsby'

import { ContentVideoGrid, Note } from '../base'

const ConductorGrid = (props) => {
  const data = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "playbooks/conductor.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 896, layout: CONSTRAINED)
        }
      }
    }
  `)
  return (
    <ContentVideoGrid
      youtubeId="EbctmDz0fYE"
      imageProps={{
        sources: data.file.childImageSharp.gatsbyImageData,
        alt: 'Photo of laptop showing the conductor interface',
      }}
      {...props}
    >
      <Heading variant="h4" font="primary">
        Conductor for <NoWrap>COVID-19</NoWrap>
      </Heading>
      <P>
        <strong>Finding calm in the chaos.</strong>
      </P>
      <P>
        Sensei Labs’ Conductor for <NoWrap>COVID-19</NoWrap> is a hub for
        knowledge, templates, and best practices for teams to plan their
        response, manage business continuity through the crisis, and accelerate
        their post-crisis recovery. This free tool includes access to content on
        operations, people, technology, and communications to provide a
        one-stop-shop for <NoWrap>COVID-19</NoWrap> teams.
      </P>
      <P>
        <Link
          type="text"
          href="https://www.senseilabs.com/conductor/conductor-for-covid-19/"
        >
          Learn more about using Conductor for COVID-19.
        </Link>
      </P>
      <Box pt={3}>
        <Note>
          <strong>Partners & Advisors:</strong>
        </Note>
        <Note>Sensei Labs</Note>
      </Box>
    </ContentVideoGrid>
  )
}

export default ConductorGrid
