import React from 'react'
import { Heading, NoWrap, P, Link } from '@klickmarketing/react-components'
import { Box } from '@material-ui/core'
import { graphql, useStaticQuery } from 'gatsby'

import { ContentImageGrid } from '../base'
import { JohnBrownstein } from '../BioCard/BioCards'

const HealthmapGrid = (props) => {
  const data = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "collaborations/healthmap.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 896, layout: CONSTRAINED)
        }
      }
    }
  `)
  return (
    <ContentImageGrid
      imageProps={{
        sources: data.file.childImageSharp.gatsbyImageData,
        alt: 'Photo of Laptop showing COVID-19 HealthMap',
      }}
      {...props}
    >
      <Heading variant="h4" font="primary">
        <NoWrap>COVID-19</NoWrap> HealthMap
      </Heading>
      <P variant="subtitle1">
        <strong>Supporting vital data visualization.</strong>
      </P>
      <P>
        HealthMap visualizes a near real-time global dataset of{' '}
        <NoWrap>COVID-19</NoWrap> cases, powered by a new approach to data
        collection. The team at Klick Health is working with HealthMap on this
        critical tool to improve their technical processes during this time
        of&nbsp;need.
      </P>
      <P>
        <Link type="text" href="https://www.healthmap.org/covid-19/">
          See the current COVID-19 HealthMap.
        </Link>
      </P>
      <Box pt={3}>
        <JohnBrownstein />
      </Box>
    </ContentImageGrid>
  )
}

export default HealthmapGrid
