import React from 'react'
import { Container } from '@material-ui/core'

import { siteMetadata } from '../../config'
import { Hr, NavBar } from '../components/base'
import Collaborations from '../components/Collaborations/Collaborations'
import FeaturedStories from '../components/FeaturedStories/FeaturedStories'
import HeroHome from '../components/Hero/HeroHome'
import InTheNews from '../components/InTheNews/InTheNews'
import Layout from '../components/Layout/Layout'
import Perspectives from '../components/Perspectives/Perspectives'
import Playbooks from '../components/Playbooks/Playbooks'

const navItems = [
  {
    hash: 'perspectives',
    label: 'Life (Sciences) After COVID-19',
    to: '/#perspectives',
  },
  {
    hash: 'featured-stories',
    label: 'Featured Stories',
    to: '/#featured-stories',
  },
  {
    hash: 'collaborations',
    label: 'Collaborations & Creations',
    to: '/#collaborations',
  },
  { hash: 'playbooks', label: 'Playbooks & Tools', to: '/#playbooks' },
  { hash: 'news', label: 'In the News', to: '/#news' },
]

const IndexPage = () => {
  return (
    <Layout
      seoProps={{
        title: siteMetadata.siteTitle,
        titleTemplate: `%s`,
      }}
    >
      <HeroHome />
      <NavBar items={navItems} />
      <Perspectives />
      <ContainedHr />
      <FeaturedStories />
      <ContainedHr />
      <Collaborations />
      <ContainedHr />
      <Playbooks />
      <ContainedHr />
      <InTheNews />
    </Layout>
  )
}

const ContainedHr = () => (
  <Container maxWidth="lg">
    <Hr />
  </Container>
)

export default IndexPage
