import React from 'react'
import {
  Decorator,
  FadeIn,
  Heading,
  match,
  reduceImages,
} from '@klickmarketing/react-components'
import { Box, Container, Grid } from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import styled from 'styled-components'

import breakpoint from '../../common/breakpoint'
import { SectionItem } from '../base'
import {
  DavidFisman,
  IsaacBogoch,
  JohnBrownstein,
  MarzyehGhassem,
  MichaelDocktor,
} from '../BioCard/BioCards'

const Advisors = () => {
  const isMobile = useMediaQuery(match.isXS)
  const logoImages = useStaticQuery(graphql`
    {
      allFile(filter: { relativePath: { glob: "logos/*.*" } }) {
        edges {
          node {
            id
            name
            childImageSharp {
              gatsbyImageData(height: 100, layout: FIXED)
            }
          }
        }
      }
    }
  `)
  const images = reduceImages(logoImages.allFile.edges)
  return (
    <SectionItem>
      <Container maxWidth="lg">
        <FadeIn from="bottom">
          <Heading variant="h4" align="center" font="primary">
            Advisors &amp; Collaborators
          </Heading>
        </FadeIn>

        <FadeIn from="bottom">
          <AdvisorsContainer pt={3}>
            <Grid
              container
              spacing={4}
              justifyContent={isMobile ? 'flex-start' : 'center'}
            >
              <Grid item>
                <ItemContainer>
                  <JohnBrownstein vertical={!isMobile} />
                </ItemContainer>
              </Grid>
              <Grid item>
                <ItemContainer>
                  <DavidFisman vertical={!isMobile} />
                </ItemContainer>
              </Grid>
              <Grid item>
                <ItemContainer>
                  <MarzyehGhassem vertical={!isMobile} />
                </ItemContainer>
              </Grid>
              <Grid item>
                <ItemContainer>
                  <IsaacBogoch vertical={!isMobile} />
                </ItemContainer>
              </Grid>
              <Grid item>
                <ItemContainer>
                  <MichaelDocktor vertical={!isMobile} />
                </ItemContainer>
              </Grid>
            </Grid>
          </AdvisorsContainer>
        </FadeIn>

        <FadeIn from="bottom">
          <Box py={5}>
            <Decorator />
          </Box>
        </FadeIn>

        <FadeIn from="bottom">
          <LogosContainer>
            <Grid
              container
              spacing={4}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={6} sm>
                <ItemContainer>
                  <Logo
                    objectFit="contain"
                    image={images['logo-trillium-health-partners']}
                    alt="Trillium Health Partners"
                  />
                </ItemContainer>
              </Grid>
              <Grid item xs={6} sm>
                <ItemContainer>
                  <Logo
                    objectFit="contain"
                    image={images['logo-tag-lab']}
                    alt="Technologies for Aging Gracefully Lab"
                  />
                </ItemContainer>
              </Grid>
              <Grid item xs={6} sm>
                <ItemContainer>
                  <Logo
                    objectFit="contain"
                    image={images['logo-sensei-labs']}
                    alt="Sensei Labs"
                  />
                </ItemContainer>
              </Grid>
              <Grid item xs={6} sm>
                <ItemContainer>
                  <Logo
                    objectFit="contain"
                    image={images['logo-ontario-health-and-long-term-care']}
                    alt="Ontario Ministry of Health and Long-Term Care"
                  />
                </ItemContainer>
              </Grid>
            </Grid>
          </LogosContainer>
        </FadeIn>
      </Container>
    </SectionItem>
  )
}

const AdvisorsContainer = styled(Box)`
  overflow: hidden;
  max-width: 375px;
  margin: 0 auto;

  ${breakpoint.sm`
    max-width: 650px;
  `}

  ${breakpoint.md`
    max-width: 755px;
  `}
  
  ${breakpoint.lg`
    max-width: none;
  `}
`

const LogosContainer = styled(Box)`
  overflow: hidden;
  max-width: 375px;
  margin: 0 auto;

  ${breakpoint.sm`
    max-width: 540px;
  `}

  ${breakpoint.md`
    max-width: 745px;
  `}
  
  ${breakpoint.lg`
    max-width: none;
  `}
`

const ItemContainer = styled.div`
  margin: 0 auto;
  text-align: center;

  ${breakpoint.sm`
    width: 162px;
  `}

  ${breakpoint.md`
    width: 230px;
  `}

  ${breakpoint.lg`
    width: 210px;
  `}
`

const Logo = styled(GatsbyImage)`
  max-width: 100%;
  max-height: 70px;

  ${breakpoint.sm`
    max-height: 100px;
  `}
`

export default Advisors
