import React from 'react'
import {
  FadeIn,
  Heading,
  NoWrap,
  P,
  reduceImages,
  Section,
} from '@klickmarketing/react-components'
import { Box, Container, Grid } from '@material-ui/core'
import { graphql, useStaticQuery } from 'gatsby'

import { ShowMore } from '../base'
import {
  AlfredWhitehead,
  AmandaHunt,
  AmyGomez,
  AmyMaish,
  BrandiHightBank,
  BrianKaiser,
  DavidBowen,
  DestrySulkes,
  DianaNagel,
  GautamGulati,
  LauraMorrison,
  LeslieJamison,
  MikeLieberman,
  PaulDesormeaux,
  RandiGoeckeler,
  SamanthaDolin,
  TimFisher,
} from '../BioCard/BioCards'

import POVCard from './POVCard'

const POV_DIR_URL = 'https://cdn.klick.com/klick-covid19'
const POVs = [
  {
    title: 'How the Delta Variant Impacts Vaccination Strategies',
    image: 'cover-how-the-delta-variant-impacts-vaccination-strategies',
    imageAlt: 'Cover image of POV',
    date: 'Aug 5, 2021',
    desc: (
      <>
        During the global vaccine rollout, Klick Consulting and Applied Sciences
        took a keen interest in tracking the data and manually scraped daily
        case and death counts to understand the impact of vaccines as they
        became more widely available. Our findings illuminate relative successes
        between the <em>First-Dose-First</em> and <em>Full-Dose</em> vaccine
        strategies as the delta variant emerged.
      </>
    ),
    authors: [PaulDesormeaux],
    downloadUrl: `${POV_DIR_URL}/KH_LSAC_How-the-Delta-Variant-Impacts-Vaccination-Strategies.pdf`,
  },
  {
    title: 'Health Equity and Vaccines',
    image: 'cover-health-equity-and-vaccines',
    imageAlt: 'Cover image of POV',
    date: 'May 21, 2021',
    desc: `As the U.S. Federal government makes unprecedented investments in vaccines, health equity is at the center of this response and much of the national conversation. Explore how to address vaccine hesitancy, boost health equity, bolster confidence in the immunization system, and strengthen public health infrastructure.`,
    authors: [BrandiHightBank],
    downloadUrl: `${POV_DIR_URL}/KH_LSAC_Healthcare-Equity-and-Vaccines.pdf`,
  },
  {
    title:
      'Four Ways to Engage External Stakeholders in the Post-Pandemic World',
    image: 'cover-four-to-engage-your-external-stakeholders',
    imageAlt: 'Cover image of POV',
    date: 'Mar 12, 2021',
    desc: `With many stakeholder interactions expected to remain virtual post-pandemic, creating a strategic Stakeholder Engagement Plan is critical. To help guide healthcare marketers into the “next normal,” we’ve created a four-point framework.`,
    authors: [RandiGoeckeler],
    downloadUrl: `${POV_DIR_URL}/KH_LSAC_Four-Ways-to-Engage-External-Stakeholders.pdf`,
  },
  {
    title: 'A Digital Canary in the Coal Mine for New COVID Variants',
    image: 'cover-canary-in-the-coal-mine',
    imageAlt: 'Cover image of POV',
    date: 'Mar 12, 2021',
    desc: `It's time for social media and machine learning to join the fight against COVID-19. Imagine if these tools were being used to analyze billions of data points a day to detect new variants and medical consequences in near real time. Just like how miners once used canaries as warning tools in the coal mines, social media could be providing the warnings we need about COVID-19 mutations to help stop the spread.`,
    authors: [DavidBowen, AlfredWhitehead],
    downloadUrl: `${POV_DIR_URL}/KH_LSAC_A-Digital-Canary-in-the-Coal-Mine.pdf`,
  },
  {
    title: 'Safe Today, Sick Tomorrow?: The Impact of Pandemic Non-Compliance',
    image: 'cover-safe-today-sick-tomorrow',
    imageAlt: 'Cover image of POV',
    date: 'Oct 9, 2020',
    desc: `Healthcare non-compliance has usually been defined as skipping appointments, not taking medications, and not eating well against HCP recommendations. But the pandemic has created two new segments of non-compliance that are (or think they are) following doctor’s orders. After the pandemic, what will be at stake if these behaviors continue?`,
    authors: [DianaNagel],
    downloadUrl: `${POV_DIR_URL}/KH_LSAC_Safe-Today-Sick-Tomorrow.pdf`,
  },
  {
    title:
      'The Legacy of COVID-19: Increased Self-Interest or Increased Solidarity?',
    image: 'cover-the-legacy-of-covid-19',
    imageAlt: 'Cover image of POV',
    date: 'July 31, 2020',
    desc: `The cornerstone of the American dream is grounded in the myth of the self-made man. The idea that the individual is paramount. Will our experience with COVID-19 help us begin to redefine and reimagine our notions of the primacy of the individual over community?`,
    authors: [AmandaHunt],
    downloadUrl: `${POV_DIR_URL}/KH_LSAC_The-Legacy-of-COVID-19.pdf`,
  },
  {
    title: 'Citizen as First Responder',
    image: 'cover-citizen-as-first-responder',
    imageAlt: 'Cover image of POV',
    date: 'July 22, 2020',
    desc: `For many years we have seen the rise of consumerism in healthcare shifting us from being passive to active players in our own health. But the COVID-19 pandemic has forced many legacy barriers to be removed in order to limit the spread of the virus and to ensure resources are focused on those that need care the most. This has accelerated access to digital options that have opened up new ways and places to manage our own health.`,
    authors: [SamanthaDolin, LeslieJamison],
    downloadUrl: `${POV_DIR_URL}/KH_LSAC_Citizen-As-First-Responder.pdf`,
  },
  {
    title: 'The Power of Scientific Rigor',
    image: 'cover-the-power-of-scientific-rigor',
    imageAlt: 'Cover image of POV',
    date: 'July 8, 2020',
    desc: `The COVID-19 pandemic has demonstrated how the application of scientific principles to policy making and business is no longer optional. How can businesses apply the scientific method to their own reopening plans in a way that protects their employees, customers, and core business?`,
    authors: [MikeLieberman],
    downloadUrl: `${POV_DIR_URL}/KH_LSAC_The-Power-of-Scientific-Rigor.pdf`,
  },
  {
    title:
      'Maintaining The Sacred Doctor-Patient Relationships Through Pandemic-Accelerated Technology Adoption',
    image: 'cover-maintaining-the-sacred-doctor-patient-relationship',
    imageAlt: 'Cover image of POV',
    date: 'June 30, 2020',
    desc: `Based on the conversations we had with physicians for this piece, it was clear that valued relationships with their patients remain a key driver of their satisfaction today. Can COVID-19’s acceleration of the adoption of telemedicine serve as a tipping point to revisit improving physician satisfaction and give them a seat at the decision-making table, bringing humanity back to medicine?`,
    authors: [LeslieJamison],
    downloadUrl: `${POV_DIR_URL}/KH_LSAC_Maintaining-The-Sacred-Doctor-Patient-Relationship.pdf`,
  },
  {
    title: 'Could a Roadmap to Wellness Solve Adherence to Health Regimens?',
    image: 'cover-could-a-roadmap-to-wellness-solve',
    imageAlt: 'Cover image of POV',
    date: 'June 22, 2020',
    desc: `We’ve been prescribed a new health regimen to which we are all expected to adhere: sheltering at home, physical distancing, hand washing, and face masks. People feel surrounded and restricted by COVID-19 all day, everywhere. There is, however, an alternative way to think about what happened in the park that sheds new light on what it’s like to adhere to a health regimen and how we can best support patients.`,
    authors: [TimFisher],
    downloadUrl: `${POV_DIR_URL}/KH_LSAC_Could-A-Roadmap-To-Wellness.pdf`,
  },
  {
    title: 'The Doctor Will Zoom You Now',
    image: 'cover-doctor-will-zoom-you-now',
    imageAlt: 'Cover image of POV',
    date: 'June 15, 2020',
    desc: `The pandemic and the need for physical distancing brought enormous pressure on regulators to alter rules that had inhibited the uptake of telehealth services. Will the changes adopted by regulators in recent months have profound implications on how medicine is done in the near future?`,
    authors: [DavidBowen],
    downloadUrl: `${POV_DIR_URL}/KH_LSAC_The-Doctor-Will-Zoom-You-Now.pdf`,
  },
  {
    title: 'Reimagining Creative Uses for Underutilized Resources',
    image: 'cover-reimagining-creative-uses',
    imageAlt: 'Cover image of POV',
    date: 'June 10, 2020',
    desc: `For most industries, sharing underutilized resources is nothing new. Could this pandemic serve as a catalyst to accelerate the application of shared resources? When faced with dire and desperate situations, a surge of creative thinking forces us to look at the same old things in brand new ways.`,
    authors: [GautamGulati],
    downloadUrl: `${POV_DIR_URL}/KH_LSAC_Reimagining-Creative-Uses-for-Underutilized-Resources.pdf`,
  },
  {
    title: 'A New Essential Model',
    image: 'cover-a-new-essential-model',
    imageAlt: 'Cover image of POV',
    date: 'June 4, 2020',
    desc: `Healthcare decision makers are determining what is considered essential and non-essential. How will that impact our future behaviors as life sciences leaders? And if what’s considered essential in healthcare is shifting, how do we change the way we educate and communicate?`,
    authors: [LauraMorrison],
    downloadUrl: `${POV_DIR_URL}/KH_LSAC_A-New-Essential-Model.pdf`,
  },
  {
    title:
      'The Value of Patient-Generated Health Data in a Post-Pandemic World',
    image: 'cover-the-value-of-patient-generated-health-data',
    imageAlt: 'Cover image of POV',
    date: 'May 28, 2020',
    desc: `The ongoing COVID-19 pandemic has delivered a seismic jolt to the importance of patient-generated health data and the need to define its role in shaping how we function as a society. How might PGHD help us build a bridge to a world beyond where we stand today?`,
    authors: [BrianKaiser],
    downloadUrl: `${POV_DIR_URL}/KH_LSAC_The-Value-Of-Patient-Generated-Health-Data.pdf`,
  },
  {
    title: 'Sometimes It’s What You Can’t See',
    image: 'cover-sometimes-its-what-you-cant-see',
    imageAlt: 'Cover image of POV',
    date: 'May 21, 2020',
    desc: `COVID-19 undermines almost anything that is shared or connected and is raising significant questions about the significance of space in our lives. How can we continue to meet the information and support-based needs through our new orientation to space?`,
    authors: [AmyMaish],
    downloadUrl: `${POV_DIR_URL}/KH_LSAC_Sometimes-Its-What-You-Cant-See.pdf`,
  },
  {
    title:
      'Lessons From COVID-19: Improving Health Behavior and Reducing Disparities',
    image: 'cover-lessons-from-covid-19',
    imageAlt: 'Cover image of POV',
    date: 'May 14, 2020',
    desc: `Life sciences leaders can leverage the current COVID-19 health 
    awareness momentum, convert it into health literacy, and direct it towards
    improvements in health behaviors. Exploring the thoughtful planning and
    nuanced communications required to meet the unique needs and barriers of
    communities of color.`,
    authors: [AmyGomez, DestrySulkes],
    downloadUrl: `${POV_DIR_URL}/KH_LSAC_Lessons-From-COVID-19-Improving-Health-Behavior-and-Reducing-Disparities.pdf`,
  },
  {
    title:
      'Can COVID-19 Design a Better World for People Living with Chronic Conditions?',
    image: 'cover-can-covid-19-design-a-better-world',
    imageAlt: 'Cover image of POV',
    date: 'May 7, 2020',
    desc: `With COVID-19, we are collectively going through an illness 
    experience. And because of the sheer number of people impacted, the world is
     rapidly adapting in big and small ways. These adaptations are showing us
     new possibilities of a world better designed for living with illness.`,
    authors: [TimFisher],
    downloadUrl: `${POV_DIR_URL}/KH_LSAC_Can-COVID-19-Design-a-Better-World-for-People-Living-with-Chronic-Conditions.pdf`,
  },
  {
    title: 'Changing Contexts Changes Habits',
    image: 'cover-changing-contexts-changes-habits',
    imageAlt: 'Cover image of POV',
    date: 'April 30, 2020',
    desc: `If there's one thing we are certain of as a result of the COVID-19 
      pandemic containment measures, it’s that our worklife behaviors have been
      fundamentally altered. The future will look different from the past, and
      life sciences companies will need to proactively adapt and reconsider
      their customer engagement playbook to one that embraces the behavioral
      changes occurring in the not-so-distant future.`,
    authors: [LeslieJamison, TimFisher],
    downloadUrl: `${POV_DIR_URL}/KH_LSAC_Changing-Contexts-Changes-Habits.pdf`,
  },
  {
    title: 'Patient Care, at the Speed (and Scale) of Science',
    image: 'cover-patient-care-at-the-speed-of-science',
    imageAlt: 'Cover image of POV',
    date: 'April 23, 2020',
    desc: `The urgency of a worldwide pandemic and the fluidity of modern 
      communications platforms have led clinicians to new, crowdsourced research
      models that deliver radical increases in speed to care. After the pandemic,
      how can we leverage the current change in mindsets toward these new research
      models and leverage the resultant behaviors?`,
    authors: [GautamGulati],
    downloadUrl: `${POV_DIR_URL}/KH_LSAC_Patient-Care-at-the-Speed-and-Scale-of-Science.pdf`,
  },
]

const Perspectives = () => {
  const povImages = useStaticQuery(graphql`
    {
      allFile(filter: { relativePath: { glob: "povs/*.*" } }) {
        edges {
          node {
            id
            name
            childImageSharp {
              gatsbyImageData(width: 568, layout: CONSTRAINED)
            }
          }
        }
      }
    }
  `)
  const images = reduceImages(povImages.allFile.edges)

  const POVGridItems = POVs.slice(0, POVs.length).map((p, index) => {
    const fadeDirection = index % 2 === 0 ? 'left' : 'right'
    return (
      <Grid key={index} item xs={12} sm={6}>
        <POVCard
          fadeDirection={fadeDirection}
          {...p}
          sources={images[p.image]}
        />
      </Grid>
    )
  })
  return (
    <Section id="perspectives" overflow="hidden">
      <Box pb={5}>
        <FadeIn from="bottom">
          <Container maxWidth="sm">
            <Heading variant="h2" align="center">
              Life (Sciences) <br />
              After <NoWrap>COVID-19</NoWrap>
            </Heading>
            <P align="center">
              Klick Health’s Life (Sciences) After COVID-19 series is a
              collection of expert perspectives designed to inform and inspire
              the life sciences community for the coming changes, challenges,
              and opportunities we anticipate as a result of this global health
              crisis.
            </P>
          </Container>
        </FadeIn>
      </Box>
      <Container maxWidth="lg">
        <Grid container spacing={10} justifyContent="flex-start">
          <ShowMore blocks={4}>{POVGridItems}</ShowMore>
        </Grid>
      </Container>
    </Section>
  )
}

export default Perspectives
