import React from 'react'

import BioCard from './BioCard'

const AmyGomez = (props) => {
  return (
    <BioCard
      bioImage="amy-gomez"
      name="Amy Gómez, PhD"
      titles={['Senior Vice President, Diversity Strategy']}
      {...props}
    />
  )
}

const PaulDesormeaux = (props) => {
  return (
    <BioCard
      bioImage="paul-desormeaux"
      name="Paul Desormeaux"
      titles={['Klick Consulting']}
      {...props}
    />
  )
}

const AmyMaish = (props) => {
  return (
    <BioCard
      bioImage="amy-maish"
      name="Amy Maish, PhD"
      titles={['Vice President, Brand Strategy']}
      {...props}
    />
  )
}

const BrianKaiser = (props) => {
  return (
    <BioCard
      bioImage="brian-kaiser"
      name="Brian Kaiser"
      titles={['Senior Vice President, Strategy']}
      {...props}
    />
  )
}

const DestrySulkes = (props) => {
  return (
    <BioCard
      bioImage="destry-sulkes"
      name="Destry Sulkes, MD"
      titles={['Executive Vice President, Growth']}
      {...props}
    />
  )
}

const GautamGulati = (props) => {
  return (
    <BioCard
      bioImage="gautam-gulati"
      name="Dr. Gautam Gulati"
      titles={['Innovator-in-Residence', 'Curator of Klick Author Series']}
      {...props}
    />
  )
}

const LeslieJamison = (props) => {
  return (
    <BioCard
      bioImage="leslie-jamison"
      name="Leslie Jamison"
      titles={['EVP Corporate Ventures']}
      {...props}
    />
  )
}

const MikeLieberman = (props) => {
  return (
    <BioCard
      bioImage="mike-lieberman"
      name="Mike Lieberman, PhD Neuroscience"
      titles={['SVP Medical Science']}
      {...props}
    />
  )
}

const TimFisher = (props) => {
  return (
    <BioCard
      bioImage="tim-fisher"
      name="Tim Fisher"
      titles={['VP Behavioral Science']}
      {...props}
    />
  )
}

const JohnBrownstein = (props) => {
  return (
    <BioCard
      bioImage="john-brownstein"
      name="Dr. John Brownstein"
      titles={[
        'Professor of Medicine, Harvard Medical School',
        'Chief Innovation Officer, Boston Children’s Hospital',
      ]}
      {...props}
    />
  )
}

const MichaelDocktor = (props) => {
  return (
    <BioCard
      bioImage="michael-docktor"
      name="Dr. Michael Docktor"
      titles={[
        'Co-Founder & CEO, Dock Health',
        'Clinical Director of Innovation, Boston Children’s Hospital',
      ]}
      {...props}
    />
  )
}

const DavidFisman = (props) => {
  return (
    <BioCard
      bioImage="david-fisman"
      name="Dr. David Fisman"
      titles={['Dalla Lana School, Public Health']}
      {...props}
    />
  )
}

const MarzyehGhassem = (props) => {
  return (
    <BioCard
      bioImage="marzyeh-ghassemi"
      name="Dr. Marzyeh Ghassemi"
      titles={['Faculty Member, Vector Institute']}
      {...props}
    />
  )
}

const IsaacBogoch = (props) => {
  return (
    <BioCard
      bioImage="isaac-bogoch"
      name="Dr. Isaac Bogoch"
      titles={['Clinician Investigator, University Health Network']}
      {...props}
    />
  )
}

const DavidBowen = (props) => {
  return (
    <BioCard
      bioImage="david-bowen"
      name="David Bowen, PhD"
      titles={['Head of Policy & Advocacy']}
      {...props}
    />
  )
}

const LauraMorrison = (props) => {
  return (
    <BioCard
      bioImage="laura-morrison"
      name="Laura Morrison"
      titles={['Senior Vice President, Brand Strategy']}
      {...props}
    />
  )
}

const AmandaHunt = (props) => {
  return (
    <BioCard
      bioImage="amanda-hunt"
      name="Amanda Hunt"
      titles={['SVP, Brand Strategy']}
      {...props}
    />
  )
}

const SamanthaDolin = (props) => {
  return (
    <BioCard
      bioImage="samantha-dolin"
      name="Samantha Dolin"
      titles={['Executive Creative Director']}
      {...props}
    />
  )
}

const DianaNagel = (props) => {
  return (
    <BioCard
      bioImage="diana-nagel"
      name="Diana Nagel"
      titles={['Vice President, Strategy']}
      {...props}
    />
  )
}

const AlfredWhitehead = (props) => {
  return (
    <BioCard
      bioImage="alfred-whitehead"
      name="Alfred Whitehead, MS, MAs"
      titles={['EVP Applied Sciences']}
      {...props}
    />
  )
}

const RandiGoeckeler = (props) => {
  return (
    <BioCard
      bioImage="randi-goeckeler"
      name="Randi Goeckeler"
      titles={['VP Stakeholder Coalitions']}
      {...props}
    />
  )
}

const BrandiHightBank = (props) => {
  return (
    <BioCard
      bioImage="brandi-bank"
      name="Brandi Hight Bank"
      titles={['VP Public Health and Policy']}
      {...props}
    />
  )
}

export {
  AmyGomez,
  AmyMaish,
  BrianKaiser,
  DestrySulkes,
  TimFisher,
  LeslieJamison,
  GautamGulati,
  JohnBrownstein,
  MichaelDocktor,
  DavidFisman,
  MarzyehGhassem,
  IsaacBogoch,
  DavidBowen,
  LauraMorrison,
  MikeLieberman,
  AmandaHunt,
  SamanthaDolin,
  DianaNagel,
  AlfredWhitehead,
  RandiGoeckeler,
  BrandiHightBank,
  PaulDesormeaux,
}
