import React from 'react'
import { Heading, P, Link } from '@klickmarketing/react-components'
import { Box } from '@material-ui/core'
import { graphql, useStaticQuery } from 'gatsby'

import { ContentImageGrid, Note } from '../base'

const HowsMyFlatteningGrid = (props) => {
  const data = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "collaborations/hows-my-flattening.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 896, layout: CONSTRAINED)
        }
      }
    }
  `)
  return (
    <ContentImageGrid
      imageProps={{
        sources: data.file.childImageSharp.gatsbyImageData,
        alt: 'Photo of Laptop showing #HowsMyFlattening interface',
      }}
      {...props}
    >
      <Heading variant="h4" font="primary">
        #HowsMyFlattening
      </Heading>
      <P variant="subtitle1">
        <strong>
          Consolidating data to perform analytics and visualization.
        </strong>
      </P>
      <P>
        A team of volunteer physicians, data scientists, and public health
        experts collecting all available data on the pandemic in Ontario in
        order to consolidate it in a centralized, open database. The database
        includes estimates of cases, testing and detection efforts, confirmed
        cases, hospitalizations, and critical care usage. By creating and
        providing analyses that are relevant to decision-makers at the
        provincial, regional, and local levels, and any others who would like to
        use it for their own analysis, #HowsMyFlattening aims to empower
        informed action throughout the outbreak.
      </P>
      <P>
        <Link type="text" href="https://howsmyflattening.ca/#/home">
          Learn more about #HowsMyFlattening.
        </Link>
      </P>
      <Box pt={3}>
        <Note>
          <strong>Partners & Advisors:</strong>
        </Note>
        <Note>Dr. David Fisman, Dalla Lana School of Public Health</Note>

        <Note>Dr. Marzyeh Ghassemi, Vector Institute</Note>

        <Note>Dr. Isaac Bogoch, University Health Network</Note>

        <Note>Trillium Health Partners</Note>

        <Note>Vector Institute</Note>

        <Note>Technologies for Aging Gracefully Lab </Note>
      </Box>
    </ContentImageGrid>
  )
}

export default HowsMyFlatteningGrid
