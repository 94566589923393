import React from 'react'
import { reduceImages } from '@klickmarketing/react-components'
import { Grid } from '@material-ui/core'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import styled from 'styled-components'

import { Note } from '../base'

const BioCard = ({
  src,
  name,
  vertical = false,
  titles = [],
  direction = 'row',
  alignItems = 'center',
  spacing = 2,
  bioImage,
  ...rest
}) => {
  const verticalProps = {
    alignItems: 'center',
    direction: 'column',
  }
  const bioImages = useStaticQuery(graphql`
    {
      allFile(filter: { relativePath: { glob: "biocards/*.*" } }) {
        edges {
          node {
            id
            name
            childImageSharp {
              gatsbyImageData(width: 100, height: 100, layout: FIXED)
            }
          }
        }
      }
    }
  `)
  const images = reduceImages(bioImages.allFile.edges)
  return (
    <Grid
      container
      direction={direction}
      alignItems={alignItems}
      spacing={spacing}
      {...(vertical && verticalProps)}
      {...rest}
    >
      <Grid item>
        <ProfileImg image={images[bioImage]} alt={name} />
      </Grid>
      <StyledGrid item xs vertical={vertical}>
        <Note>
          <strong>{name}</strong>
        </Note>
        {titles.map((title) => (
          <Note key={title}>{title}</Note>
        ))}
      </StyledGrid>
    </Grid>
  )
}

const ProfileImg = styled(GatsbyImage)`
  border-radius: 50%;
`

const StyledGrid = styled(({ vertical, ...rest }) => <Grid {...rest} />)`
  text-align: ${(props) => (props.vertical ? 'center' : 'left')};
`

export default BioCard
