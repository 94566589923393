import React from 'react'
import { Heading, match, NoWrap, P } from '@klickmarketing/react-components'
import { Box, Container } from '@material-ui/core'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage, withArtDirection } from 'gatsby-plugin-image'
import styled from 'styled-components'

const BreakingNews = () => {
  const imageQuery = useStaticQuery(graphql`
    {
      mobileImage: file(
        relativePath: { eq: "featured-stories/masks-mobile.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      desktopImage: file(relativePath: { eq: "featured-stories/masks.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)

  const masksImage = withArtDirection(getImage(imageQuery.mobileImage), [
    {
      media: match.getQuery('isSM'),
      image: getImage(imageQuery.desktopImage),
    },
  ])

  return (
    <>
      <BreakingBanner color="white">
        <StyledHeading
          variant="h3"
          component="h2"
          font="primary"
          align="center"
          paragraph={false}
        >
          400,000 Masks Donated
        </StyledHeading>
        <BannerImage
          image={masksImage}
          alt="Stacked boxes of PPE inside Klick offices"
        />
      </BreakingBanner>
      <Box pt={3}>
        <Container maxWidth="md">
          <P align="center">
            The Frontlines.Health Foundation has just made a donation of 400,000
            masks to hospitals and healthcare systems in response to the urgent
            need for personal protective equipment (PPE) for the city’s
            frontline healthcare professionals combating&nbsp;
            <NoWrap>COVID-19</NoWrap>.
          </P>
          <P align="center">
            This donation is being allocated to a number of healthcare providers
            to help protect as many local medical teams as possible during the
            rapidly evolving pandemic and will significantly increase the number
            of new respiratory face masks being obtained for frontline
            healthcare professionals in Toronto.
          </P>
        </Container>
      </Box>
    </>
  )
}

const BannerImage = styled(GatsbyImage)`
  ${match.isSM} {
    & > div:first-child {
      padding-top: 32.56578947368421% !important;
    }
  }
`

const BreakingBanner = styled(Box)`
  position: relative;
`

const StyledHeading = styled(Heading)`
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  width: 50%;
  min-width: 200px;
  transform: translate(-50%, -50%);
  color: #fff;
`

export default BreakingNews
