import React from 'react'
import {
  Button,
  Link,
  FadeIn,
  Heading,
  P,
  tracking,
} from '@klickmarketing/react-components'
import { Box, Grid } from '@material-ui/core'
import { GatsbyImage } from 'gatsby-plugin-image'

const trackDownload = ({ label, downloadUrl }) => {
  tracking.track({
    event: 'file_download',
    eventModel: {
      file_extension: 'pdf',
      file_name: downloadUrl.split('/').pop(),
      track_id: label,
      link_url: downloadUrl,
    },
  })
}

const POVCard = ({
  imageUrl,
  imageAlt,
  title,
  date,
  desc,
  authors,
  downloadUrl,
  sources,
  fadeDirection,
  ...rest
}) => {
  return (
    <Box {...rest}>
      <FadeIn from={fadeDirection}>
        <Box pb={3}>
          <GatsbyImage image={sources} alt={imageAlt} />
        </Box>
      </FadeIn>
      <FadeIn from={fadeDirection}>
        <div>
          <Heading variant="h4" font="primary">
            {title}
          </Heading>
          <P>{desc}</P>
          <Box py={3}>
            <Grid container spacing={6} justifyContent="space-between">
              {authors.map((Author, index) => (
                <Grid key={index} item sm={6}>
                  <Author />
                </Grid>
              ))}
            </Grid>
          </Box>
          {downloadUrl && (
            <Button
              component={Link}
              linkTracking={false}
              role="link"
              size="large"
              href={downloadUrl}
              onClick={() => trackDownload({ title, downloadUrl })}
            >
              Download
            </Button>
          )}
        </div>
      </FadeIn>
    </Box>
  )
}

export default POVCard
