import React from 'react'
import {
  Button,
  Link,
  NoWrap,
  P,
  tracking,
} from '@klickmarketing/react-components'
import { Box } from '@material-ui/core'
// import { format } from 'date-fns'
import { graphql, useStaticQuery } from 'gatsby'
// import isEmpty from 'lodash/isEmpty'

// import { LATEST_COVID_PROTOCOLS_PDF } from '../../../config'
// import useS3FileLatest from '../../hooks/useS3FileLatest'
import { ContentImageGrid, Note } from '../base'

const trackDownload = ({ label, url }) => {
  tracking.track({
    event: 'file_download',
    eventModel: {
      file_extension: 'pdf',
      file_name: url.split('/').pop(),
      track_id: label,
      link_url: url,
    },
  })
}

const SafetyProtocolsGrid = (props) => {
  const { file: image } = useStaticQuery(graphql`
    query InitialPdf {
      # latestCovidPdf {
      #   filename
      #   date
      # }
      file(relativePath: { eq: "playbooks/saftey-protocols.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 896, layout: CONSTRAINED)
        }
      }
    }
  `)
  // const latest = useS3FileLatest(LATEST_COVID_PROTOCOLS_PDF)
  // const fileObj = !isEmpty(latest) ? latest : initialPdf
  // const dateUpdated = format(new Date(fileObj.date), 'MMMM do, yyyy')
  const dateUpdated = "April 17th, 2020"
  return (
    <ContentImageGrid
      imageProps={{
        sources: image.childImageSharp.gatsbyImageData,
        alt: 'Klick Health Safety Protocols & Continuity Planning in Response to COVID-19',
      }}
      {...props}
    >
      <P variant="h5">
        <strong>
          A downloadable example of our <NoWrap>COVID-19</NoWrap> policies that
          other employers can leverage and adapt for their own unique needs.
        </strong>
      </P>
      <Button
        component={Link}
        role="link"
        size="xlarge"
        href="https://cdn.klick.com/klick-covid19/Klick_COVID19_Safety_Protocols_VF16.1_2020-04-17.pdf"
        linkTracking={false}
        target="_blank"
        onClick={() =>
          trackDownload({
            label: 'COVID-19 Response PDF',
            url: "https://cdn.klick.com/klick-covid19/Klick_COVID19_Safety_Protocols_VF16.1_2020-04-17.pdf",
          })
        }
      >
        Download
      </Button>
      <Box pt={3}>
        <Note>
          <strong>A Note on Using This Material</strong>
        </Note>
        <Note>
          This document is posted as of {dateUpdated}, and, due to the novel and
          fluid nature of the <NoWrap>COVID-19</NoWrap> outbreak, it reflects
          our best knowledge at the time of posting. This guide is for
          informational purposes only and is not intended to be a substitute for
          professional medical advice, diagnosis, or treatment and is not meant
          to replace any official guidance provided by the World Health
          Organization (WHO), the Centers for Disease Control and Prevention
          (CDC), Health Canada, or any other official regulatory body. For any
          questions and for the latest updates on the status of the{' '}
          <NoWrap>COVID-19</NoWrap> outbreak, please seek the advice of your
          official and local health body.
        </Note>
      </Box>
    </ContentImageGrid>
  )
}

export default SafetyProtocolsGrid
